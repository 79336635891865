<template>
  <div>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :row-clicked="navigateToItem"
      :table-config-options="{
        mergedEndpoint: true,
        endpoint: `${MODULE_NAME}/purchaseCreditList`
      }"
    >
      <template #cell(order_no)="{ data: { item } }">
        <div>
          <span>
            {{ item.number ? getValueFromGivenObjectByKey(item, 'number', '&mdash;') : item.id }}
          </span>
        </div>
      </template>
      <template #cell(state)="{ data: { item } }">
        <status-filter-table :item="item" />
      </template>
      <template #cell(order_date)="{ data: { item } }">
        {{ item.order ? mapDate(getValueFromGivenObjectByKey(item, 'order.created_at', '&mdash;')) : mapDate(item.created_at) }}
      </template>
      <template #cell(supplier)="{data: { item }}">
        <div
          class="d-flex align-items-center"
          style="gap: 4px"
        >
          <!--   LTickIcon       -->
          {{ getValueFromGivenObjectByKey(item, 'supplier.cust_name_dba', '&mdash;') }}
        </div>
      </template>
      <template #cell(internal_order_reference)="{ data: { item } }">
        {{ item.internal_order_reference ? getValueFromGivenObjectByKey(item, 'internal_order_reference', '&mdash;') : getValueFromGivenObjectByKey(item, 'credit_note_order', '&mdash;') }}
      </template>
      <template #cell(amount)="{ data: { item } }">
        <div class="text-right">
          {{ formatNumberToCurrencyView(getValueFromGivenObjectByKey(item, 'total', 0)) }}
        </div>
      </template>
      <template #manage-monthly-date>
        <manage-monthly-date />
      </template>
      <!--   Table Top Right Side   -->
      <template #status-filter-tab>
        <status-filter-tab />
      </template>
      <template #filter>
        <status-filter-color-key />
      </template>
    </l-table>
  </div>
</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey, mapDate, formatNumberToCurrencyView } from '@/@core/utils/utils'

// Components
import StatusFilterTab from '@/views/main/orders/view/purchase/components/list/StatusFilterTab.vue'
import ManageMonthlyDate from '@/views/main/orders/components/use-as-global/ManageMonthlyDate.vue'
import StatusFilterTable from '@/views/main/orders/view/purchase/components/list/StatusFilterTable.vue'
import StatusFilterColorKey from '@/views/main/orders/components/use-as-global/StatusFilterColorKey.vue'
// eslint-disable-next-line import/no-cycle
import warehouseConfig from '@/views/main/warehouse/main-order/config'
import config from '../config'

export default {
  name: 'List',
  components: {
    StatusFilterColorKey,
    StatusFilterTable,
    ManageMonthlyDate,
    StatusFilterTab,
    LTable,
  },
  methods: {
    navigateToItem({
      id, state, status, credit_note_order,
    }) {
      const orderItem = {
        order_type: this.ORDER_TYPES_KV.PO,
        fulfillment_state: state,
        fulfillment_status: status,
        id,
      }
      if ((state === 2 && status === 4) || state >= 3) {
        this.handleOrderStatus(this, orderItem)
        return
      }
      const {
        redirectPath,
        redirectPathOnStatusReadyStateZero,
        redirectPathOnStatusReadyStateTwo,
        redirectPathOnStatusRejectedStateOne,
        redirectPathOnStatusSaveAsDraftStateZero,
      } = this.orderPurchaseStatesRedirectToPages.find(redirectItem => redirectItem.state === state)
      // eslint-disable-next-line no-mixed-operators,camelcase
      if (credit_note_order !== undefined && status === 1 && (state === 0 || state === 1)) {
        this.$router.push({ name: 'credit-note-update-quote', params: { id: id.slice(3, 5) } })
        // eslint-disable-next-line camelcase
      } else if (credit_note_order !== undefined && status === 1 && state === 2) {
        this.$router.push({ name: 'credit-note-paid-quote', params: { id: id.slice(3, 5) } })
      } else if (this.ORDERS_STATUS_READY_STATUS === status && state === 0) {
        this.$router.push({ name: redirectPathOnStatusReadyStateZero, params: { id } })
      } else if (status === 1 && state === 0) {
        this.$router.push({ name: redirectPathOnStatusSaveAsDraftStateZero, params: { id } })
      } else if (status < this.ORDERS_STATUS_READY_STATUS && state === 2) {
        this.$router.push({ name: redirectPathOnStatusReadyStateTwo, params: { id } })
      } else if (this.ORDERS_STATUS_READY_STATUS === status && state === 2) {
        this.$router.push({ name: redirectPath, params: { id } })
      } else if (this.ORDERS_STATUS_REJECTED === status && state === 1) {
        this.$router.push({ name: redirectPathOnStatusRejectedStateOne, params: { id } })
      } else if (this.ORDERS_STATUS_REJECTED === status && state === 0) {
        this.$router.push({ name: redirectPathOnStatusSaveAsDraftStateZero, params: { id } })
      } else {
        this.$router.push({ name: redirectPath, params: { id } })
      }
    },
  },
  setup() {
    const {
      MODULE_NAME, tableColumns, orderPurchaseStatesRedirectToPages, ORDERS_STATUS_READY_STATUS, ORDERS_STATUS_REJECTED,
    } = config()
    const { ORDER_TYPES_KV, handleOrderStatus } = warehouseConfig()

    return {
      mapDate,
      MODULE_NAME,
      tableColumns,
      ORDERS_STATUS_REJECTED,
      formatNumberToCurrencyView,
      ORDERS_STATUS_READY_STATUS,
      getValueFromGivenObjectByKey,
      orderPurchaseStatesRedirectToPages,
      ORDER_TYPES_KV,
      handleOrderStatus,
    }
  },
}
</script>
